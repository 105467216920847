var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4"},[_c('v-card-title',[_vm._v("Buckets")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.buckets,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.presentBucketDialog()}}},[_vm._v("Create Bucket")])],1)]},proxy:true},_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[(header.tooltip)?_c('span',[_vm._v(_vm._s(header.tooltip))]):_vm._e()])]}}}),{key:"item.modifiedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocalTime(item.modifiedAt))+" ")]}},{key:"item.viewAction",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.navigateToBucket(item)}}},on),[_vm._v(" "+_vm._s(_vm.icons.bucket)+" ")])]}}],null,true)},[_c('span',[_vm._v("View in Cloud Storage")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(_vm.icons.dotsVertical))])]}}],null,true)},[_c('v-list',[_c('v-list-item',{key:"delete",on:{"click":function($event){return _vm.presentDeleteDialog(item)}}},[_c('v-list-item-title',{staticStyle:{"color":"red"}},[_vm._v("Delete Bucket")])],1)],1)],1)]}}],null,true)}),(_vm.showDialog)?_c('Dialog',{attrs:{"title":_vm.deleteDialogTitle,"text":_vm.deleteDialogText,"confirmButtonColor":"red darken-1","confirmButtonText":"Delete"},on:{"confirmed":function($event){return _vm.deleteBucket(_vm.selectedItem)}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e(),(_vm.showError)?_c('Dialog',{attrs:{"title":_vm.errorDialogTitle,"text":_vm.errorDialogText,"cancelButtonEnabled":false},on:{"confirmed":function($event){_vm.showError = false}},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}}):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[(_vm.showCreateBucket)?_c('v-dialog',{attrs:{"persistent":"","max-width":"390"},model:{value:(_vm.showCreateBucket),callback:function ($$v) {_vm.showCreateBucket=$$v},expression:"showCreateBucket"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(!this.dialogBucket.editing ? 'Create Bucket' : 'Edit Bucket'))]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{staticClass:"px-4"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|bucketNameRule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":_vm.dialogBucket.editing === true,"error-messages":errors,"counter":222,"label":"Bucket Name","required":""},model:{value:(_vm.dialogBucket.bucketName),callback:function ($$v) {_vm.$set(_vm.dialogBucket, "bucketName", $$v)},expression:"dialogBucket.bucketName"}})]}}],null,true)})],1)]}}],null,false,2598894605)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();_vm.showCreateBucket = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveBucket.apply(null, arguments)}}},[_vm._v(_vm._s(!this.dialogBucket.editing ? 'Create' : 'Update'))])],1)],1)],1):_vm._e()],1),_c('v-dialog',{model:{value:(_vm.showAccounts),callback:function ($$v) {_vm.showAccounts=$$v},expression:"showAccounts"}},[_c('v-card',[_c('AccountsCard',{key:_vm.componentKey,attrs:{"selectedDataset":_vm.selectedItem},on:{"close":function($event){return _vm.accountsClosed()}}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeAccountsDialog.apply(null, arguments)}}},[_vm._v("Close")])],1)],1)],1),_c('v-card-text',{staticStyle:{"height":"100px","position":"relative"}},[_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"pink","dark":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":function($event){return _vm.presentBucketDialog()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.plus))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }