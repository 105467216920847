<template>
  <BucketsCard />
</template>

<script>
import BucketsCard from '@/components/BucketsCard';

export default {
  components: {
    BucketsCard
  }
};
</script>
